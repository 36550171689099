<template>


<div v-if="showScheduler" class="tcs-modal">

  <div  class="scheduler-container" >
    
    <div id="scheduler-main">
      <span class="circle">&#128222;</span>
      <h1>Join Our Waitlist</h1>
      <p>
        This offer is now closed. Please leave your contact information and a representative from our VIP team will reach out the moment a space is available. 
      </p>

      <div id="tcs-error" v-if="errorMessages.length">
          <ul>
              <li v-for="e in errorMessages" v-bind:key="e.id">{{e}}</li>
          </ul>
      </div>

      <form id="LeadGen" @submit.prevent="processForm">

        <input 
          id="FirstName" 
          name="FirstName" 
          placeholder="First Name" 
          maxlength="20" type="text" 
          v-model="FirstName" 
          required 
          />

        <input
          id="LastName"
          name="LastName"
          placeholder="Last Name"
          maxlength="30"
          type="text"
          v-model="LastName" 
          required
        />

        <input
          name="email"
          maxlength="50"
          placeholder="Email"
          type="text"
          v-model="email"
          id="email"
        />

        <input
          id="PhoneNumber"
          name="PhoneNumber"
          placeholder="Phone Number: (555) 555-5555"
          maxlength="14"
          type="text"
          v-model="PhoneNumber"
          v-on:keyup="formatPhone()"
          required
        />

        <input name="submit" type="submit" value="Submit" class="continue" />


        <p>
          We value your
          <a href="http://oxfordclub.com/privacy-policy/" target="_blank"
            >privacy</a
          >. *All time slots are in Eastern Time (ET).
        </p>
      </form>
    </div>
    <div class="scheduler-confirmation">
      <div class="circle-loader">
        <div class="checkmark draw"></div>
      </div>
      <br />
      <p class="confirmation-message"></p>
    </div>  
  
  </div>
</div>
  
</template>

<script>

var moment = require('moment-timezone');
var momentBusiness = require('moment-business-time');

export default {
  name: "SchedulerModal",
  data: function data() {
          return {
              FirstName: '',
              LastName: '',
              email: '',
              PhoneNumber: '',
              bestTimeToCall: '',
              errorMessages: [],
              moment: moment,
              momentBusiness: momentBusiness
          };
      },
  props: {
    showScheduler: Boolean,
  },
   methods: {

     dateChanged: function(value){
       this.bestTimeToCall = value;
     },

     formatPhone() {
       let val = this.PhoneNumber;
       this.PhoneNumber = val.replace(/\D/g, '').replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, function(txt, f, s, t) {
            if (t) {
                return `(${f}) ${s}-${t}`;
            } else if (s) {
                return `(${f}) ${s}`;
            } else if (f) {
                return `(${f})`;
            }
        });
     },
 
    processForm: function(e) {
        let multivariateId = '1049649';
        let notSaveSignup = false;  

        let formData = {
            multivariateId: multivariateId,
            notSaveSignup: notSaveSignup,
            firstName: this.FirstName,
            lastName: this.LastName,
            email: this.email,
            phoneNumber: encodeURIComponent(this.PhoneNumber.trim()),
            bestTimeToCall: new Date(this.bestTimeToCall.replace(/-/g, "/"))
        }

        this.errorMessages = validateForm(formData);

        console.log('ERROR: ' + this.errorMessages.join("\n"));

        if (this.errorMessages.length > 0) {
            e.preventDefault();
        } else {
            submitForm(formData);
        }
    }
}
};

function validateForm(data) {

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    let messages = [];

    if (data.phoneNumber.length < 10) {
        messages.push('Please enter a valid phone number.');
    }

    if (!data.email.match(validRegex)) {
        messages.push('Please enter a valid email address.');
    }

    return messages;
}


function submitForm(data) {

  const currentPageURL = window.location.href;
  // const currentPageURL = 'https://s3.amazonaws.com/assets.oxfordclub.com/offer-expired/index.html?campaign=PUB%20Campaign';

  // const url = 'http://127.0.0.1:8000/store?' + '&email=' + data.email + '&firstName=' + data.firstName + '&lastName=' + data.lastName + '&phone=' + data.phoneNumber + '&bestTimeToCall=' + moment(data.bestTimeToCall).utcOffset(0, true).toISOString() + '&referringURL=' + currentPageURL + '&promoCode=' + getPromoCode(currentPageURL) + '&campaignName=' + getCampaignName() + '&isContingencyLead=true';

  const url = 'https://guqin.ocweb.tools/store?' + '&email=' + data.email + '&firstName=' + data.firstName + '&lastName=' + data.lastName + '&phone=' + data.phoneNumber + '&bestTimeToCall=' + moment(data.bestTimeToCall).utcOffset(0, true).toISOString() + '&referringURL=' + currentPageURL + '&promoCode=' + getPromoCode(currentPageURL) + '&campaignName=' + getCampaignName() + '&isContingencyLead=true';
  
  console.log('Request: ' + url);



  fetch(url, {
    method: 'GET',
    }).then(res => console.log(res));

    showConfirmationMessage();
}


function getCampaignName() {

  let searchParams = new URLSearchParams(window.location.search);

  if(searchParams.has('campaign')) {
    return searchParams.get('campaign');
 
  } else {
    return 'Not%20available';
  
  }

}

function getPromoCode(currentPageURL) {

  if(currentPageURL.includes("promoCode")) {
      return currentPageURL.slice(currentPageURL.search(/\bpromoCode\b/g)).slice(10, 18);

  } else if(currentPageURL.includes("pageNumber")) {
      let pageURLArray = currentPageURL.split('/');
      return pageURLArray[4];

  } else {
      return 'Not%20available';
  }

}

function showConfirmationMessage() {
    const schedulerMain = document.getElementById('scheduler-main');
    const confirmation = document.getElementsByClassName('scheduler-confirmation');
    const circleLoader = document.getElementsByClassName('circle-loader');
    const checkmark = document.getElementsByClassName('checkmark');
    const confirmationMessage = document.getElementsByClassName('confirmation-message');

    schedulerMain.style.display = "none";
    confirmation[0].style.display = "block";
    circleLoader[0].classList.toggle('load-complete');
    checkmark[0].style.display = "block";
    confirmationMessage[0].innerHTML = "We have added you to the waitlist. A member from our VIP Team will reach out to you directly when a spot becomes available.";
}

</script>