<template>
  <div id="app">
    <SchedulerModal
       v-bind:showScheduler="showScheduler"
       v-bind:toggleScheduler="toggleScheduler"
    />
  </div>
</template>

<script>
import SchedulerModal from "./components/SchedulerModalComponent.vue";

export default {
  name: "App",
  components: {
    SchedulerModal,
  },
  methods: {
    toggleScheduler: function () {
      this.showScheduler = this.showScheduler ? false : true;
    },
  },
  data() {
    return {
      showScheduler: true,
    };
  },
  created() {

    const getCampaignName = e => {
        console.log( "Portrait Page Loaded: " + e);
        // console.log("Campaign Name:  " + document.Portrait.dataLayer.effort.CampaignName);
    }

    let portraitTrackerScript = document.createElement('script');
   
    portraitTrackerScript.setAttribute('src', 'https://portrait-tracker.s3.amazonaws.com/all.js');
   
    document.head.appendChild(portraitTrackerScript);

    document.addEventListener('portraitPageLoaded', getCampaignName);
  }
};

</script>
